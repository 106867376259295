.__woman-bg-auth-page {
	background-image: url("assets/images/selfcare-cosmetology-beauty-concept-sensual-attractive-relaxed-naked.png");
	background-size: cover;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	color: rgba(0, 0, 0, 0);
	opacity: 1;
	display: block;
	background: url(./assets/icons/arrow-down.svg) no-repeat;
	width: 20px;
	height: 20px;
	border-width: thin;
}


input[type="radio"] + label span {
	transition: background 0.2s, transform 0.2s;
 }
 
 input[type="radio"] + label span:hover,
 input[type="radio"] + label:hover span {
	transform: scale(1.2);
 }
 
 input[type="radio"]:checked + label span {
	background-color: white;
	box-shadow: 0px 0px 0px 4px #24324F inset;
 }
 
 input[type="radio"]:checked + label {
	color: #24324F;
 }